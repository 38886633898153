import type { UseFetchOptions } from "nuxt/app"; 
type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

const BASE_URL = process.env.API_AUTH_BASE;
export interface IResultData<T> {
    code: number;
    data: T;
    msg: string;
    key: number;
}
class HttpRequest {
    request<T = any>(
      baseURL: string,
      url: string,
      method: Methods,
      data: any,
      options?: UseFetchOptions<T>,
      headers: any = {}
    ) {
      return new Promise((resolve, reject) => {
        const newOptions: UseFetchOptions<T> = {
          baseURL: baseURL,
          method: method,
          headers:{
            // "Authorization": "Bearer " + localStorage.getItem("token"),
            "CToken": localStorage.getItem("CToken"),
            ...headers
          },
          ...options,
        };
  
        if (method === "GET" || method === "DELETE") {
          newOptions.params = data;
        }
        if (method === "POST" || method === "PUT") {
          newOptions.body = data;
        }
        
        useFetch(url, newOptions)
          .then((res:any) => { 
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    // 封装常用方法
    get<T = any>(baseURL:string,url: string, params?: any, options?: UseFetchOptions<T>) {
        return this.request(baseURL,url, "GET", params, options);
    }

    post<T = any>(baseURL:string,url: string, data: any, options?: UseFetchOptions<T>) {
        return this.request(baseURL,url, "POST", data, options);
    }

    Put<T = any>(baseURL:string,url: string, data: any, options?: UseFetchOptions<T>) {
        return this.request(baseURL,url, "PUT", data, options);
    }

    Delete<T = any>(baseURL:string,url: string, params: any, options?: UseFetchOptions<T>) {
        return this.request(baseURL,url, "DELETE", params, options);
    }
}

const httpRequest = new HttpRequest();

export default httpRequest;
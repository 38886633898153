import httpRequest from "@/service/index";
 
/**
 * @description get new leads
 * @return {Promise}
 */
const BASE_URL:any = process.env.AUTH_BASE;

const new_leads = "/new_leads"; 
 
 
const getNewLeadsService = (data: any) =>{
    return httpRequest.post(BASE_URL,new_leads, data).then((res: any) => {
        console.log(res);
        const data = res.data._rawValue;
        if(data === undefined || data === null || data === ""){
            return Promise.reject(res.error.value.data);
        }else {
            return Promise.resolve(data);
        }
    }).catch((err: any) => {
        return Promise.reject(err);
    });
}
 
export {getNewLeadsService}